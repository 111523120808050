import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as jwt from 'jose';

@Injectable( {
	providedIn: 'root'
} )

export class TokenService {

	private celToken: string = '';
	private antaresToken: string = '';
	private launchSystem = environment.launchSystem;

	public getCelToken (): string {
		return this.celToken;
	}

	public setCelToken ( token: string ): void {
		this.celToken = token;
	}

	public getAntaresToken (): string {
		return this.antaresToken;
	}

	public setAntaresToken (): void {
		this.generateAntaresToken();
	}

	public getLaunchSystem (): string {
		return this.launchSystem;
	}

	private generateAntaresToken (): void {
		const secret = new TextEncoder().encode( environment.antaresKey );
		const today = new Date();
		//Setting 4 hours of expiry for JWT
		today.setHours( today.getHours() + 4 );
		( new jwt.SignJWT( { aud: this.getLaunchSystem(), iexp: today.getUTCHours() + ':' + today.getUTCMinutes() } ).setProtectedHeader( {
			alg: 'HS256'
		} ).sign( secret ) ).then( ( token: string ) => {
			this.antaresToken = token;
		} );
	}
}
