import { createReducer, on } from '@ngrx/store';
import * as appActions from './app.actions';
import { User } from '../core/models/user.model';
import { DeviceData, RcdData, RequestData, RequestDeviceParameters, RequestNetworkParameters } from '../common/models/antart-api-request.model';
import { DeviceSetting, NetworkParameters, Selectivity, ResponseDeviceData, UserSetting, NetworkSetting, CableParameters, ResponseData, Notifications, LegendData, RcdResponseInfo, Response } from '../common/models/antar-api-response.model';
import { v4 as uuid } from 'uuid';
import { Loader } from '../common/models/antar-ui.model';
import { AntarConstants } from '../common/antar.constants';
import { RcdUtils } from '../core/rcd.utils';

export interface State {
	user: User | null;
	requestData: RequestData;
	deviceData: ResponseDeviceData[];
	deviceSetting: DeviceSetting[];
	networkParameters: NetworkParameters[];
	userSetting: {
		device: UserSetting[],
		common: NetworkSetting[]
	};
	selectivity:Selectivity | null;
	loader:{
		mainLoader: Loader,
		informationLoader: Loader
	};
	notification: Notifications[];
	rcdDeviceData: RcdData[];
	rcdDeviceResponse: RcdResponseInfo[];
	userSelection: { chartType: string };
	rcdSettings: DeviceSetting[];
	selectivityByTable: Response|null;
}

export const initialState: State = {
	user: null,
	requestData: { applicationName: '', data: [], networkParameters: [], rcdData: [] },
	deviceData: [],
	deviceSetting: [],
	networkParameters: [],
	userSetting: { device: [], common: [] },
	selectivity: null,
	loader: { mainLoader: { load: false, message: '' }, informationLoader: { load: false, message: '' } },
	notification: [],
	rcdDeviceData: [],
	rcdDeviceResponse: [],
	userSelection: { chartType: 'It' },
	rcdSettings: [],
	selectivityByTable: null
};

export const appReducer = createReducer(
	initialState,
	on( appActions.LoadUser, ( state: State, { user }: { user: User } ) => ( { ...state, user: user } ) ),
	on( appActions.ResetStates, () => initialState ),
	on( appActions.UpdateInputRequestData, ( state: State, { deviceId, paramName, paramValue, checked }: { deviceId: string, paramName: string, paramValue: string, checked?: boolean } ) => {
		const updatedRequestData = state.requestData.data.map( ( data: DeviceData ) => {
			if ( data.deviceId !== '' && data.deviceId !== null && deviceId === data.deviceId ) {
				const updatedDeviceNetworkParameters = data.deviceNetworkParameters.map( ( deviceParam ) => {
					if ( paramName === deviceParam.paramName ) {
						return { ...deviceParam, paramValue: paramValue };
					}
					return deviceParam;
				} );
				return { ...data, deviceNetworkParameters: updatedDeviceNetworkParameters };
			}
			return data;
		} );
		const updatedUserSettingDevice = state.userSetting.device.map( ( deviceData: UserSetting ) => {
			if ( deviceData.deviceId !== '' && deviceData.deviceId !== null && deviceData.settingName === paramName && deviceData.deviceId === deviceId ) {
				return { ...deviceData, deviceId: deviceData.deviceId, settingName: paramName, checked: checked, value: paramValue, deviceName: deviceData.deviceName };
			} else {
				return deviceData;
			}
		} );
		return {
			...state,
			requestData: {
				...state.requestData,
				data: updatedRequestData
			},
			userSetting: {
				...state.userSetting,
				device: updatedUserSettingDevice
			}
		};
	} ),
	on( appActions.LoadInputDeviceData, ( state: State, { inputDeviceData }: { inputDeviceData: RequestData } ) => {
		const userSettingsData: UserSetting[] = [];
		const commonSettings: NetworkSetting[] = [];

		const rcdDeviceData: RcdData[] = [];
		return {
			...state,
			requestData: {
				...inputDeviceData,
				networkParameters: inputDeviceData.networkParameters
					?.filter( ( parameter: RequestNetworkParameters | undefined ) => parameter !== undefined )
					.filter( ( parameter: RequestNetworkParameters ) => AntarConstants.CABLE_TYPE.includes( parameter.paramName ) ),
				data: inputDeviceData.data.map( ( data: DeviceData ) => {
					const deviceName = data.deviceName;
					const deviceId = data.deviceId !== '' && data.deviceId !== null ? data.deviceId : uuid();

					if ( data.deviceNetworkParameters ) {
						data.deviceNetworkParameters.forEach( ( parameter: NetworkParameters ) => {
							userSettingsData.push( {
								deviceId: deviceId,
								settingName: parameter.paramName,
								value: parameter.paramValue,
								checked: true,
								deviceName: deviceName
							} );
						} );
					}

					inputDeviceData.networkParameters.map( ( parameter: CableParameters ) => {
						if (
							data.isFocusedForCableCalc !== AntarConstants.BOOLEAN_STRING['FALSE'] &&
							( AntarConstants.CABLE_TYPE.find( ( cableType ) => cableType === parameter.paramName ) )
						) {
							commonSettings.push( {
								deviceId: deviceId,
								settingName: parameter.paramName,
								value: parameter.paramValue
							} );
						}
					} );

					if ( data.deviceId !== '' && data.deviceId !== null ) {
						return data;
					} else {
						return { ...data, deviceId: deviceId, rcdPositioning: data.breakerPositioning };
					}
				} ),
				rcdData: inputDeviceData.rcdData?.map ( ( rcddata: RcdData ) => {
					const rcdId: string = uuid();
					const correspondingCbData = inputDeviceData.data.find( cbData => cbData.deviceId === rcddata.deviceId );
					const rcdPositioning = correspondingCbData?.breakerPositioning;
					rcdDeviceData.push( {
						rcdId: rcddata.rcdId === '' ? rcdId : rcddata.rcdId,
						deviceId: rcddata.deviceId,
						deviceName: rcddata.deviceName,
						manufacturerId: rcddata.manufacturerId,
						rcdDesignation: rcddata.rcdDesignation,
						sensitivity: rcddata.sensitivity,
						isAdjustable: rcddata.isAdjustable,
						sensitivityList: rcddata.sensitivityList,
						tempoTimeList: rcddata.tempoTimeList,
						rcdParameters: rcddata.rcdParameters,
						breakerPoisitioning: rcdPositioning ?? '',
						rating: rcddata.rating
					} );
					if ( rcddata.rcdParameters )
					{
						rcddata.rcdParameters.forEach( ( parameters: RequestDeviceParameters ) => {
							userSettingsData.push ( {
								deviceId: rcdId,
								settingName: parameters.paramName,
								value: parameters.paramValue
							} );
						} );
					}
					return { ...rcddata, rcdDeviceData: rcdDeviceData };
				} )
			},
			userSetting: {
				...state.userSetting,
				common: [ ...state.userSetting.common, ...commonSettings ],
				device: [ ...state.userSetting.device, ...userSettingsData ]
			},
			rcdDeviceData: rcdDeviceData,
			rcdSettings: RcdUtils.getRcdSettings( rcdDeviceData ),
			rcdDeviceResponse: processRcdResponse( userSettingsData, rcdDeviceData, RcdUtils.getRcdSettings( rcdDeviceData ) )
		};
	} ),
	on( appActions.LoadResponseDataSuccess, ( state: State, { responseData }: { responseData: ResponseData } ) => {
		const deviceData: ResponseDeviceData[] = [];
		const deviceSetting : DeviceSetting[] = [];
		const updatedData = state.requestData.data.map( ( requestd ) => ( { ...requestd } ) );
		responseData['tccDataResponse']['curveDataPoints'].forEach( ( rawDeviceData: ResponseDeviceData ) => {
			const legendDataList = rawDeviceData?.['settingsData']?.legendDataList?.map( ( legendData: LegendData ) => {
				const rest = Object.fromEntries( Object.entries( legendData ).filter( ( [ key ] ) => key !== 'displayName' ) );
				return rest as LegendData;
			} );
			deviceSetting.push( {
				deviceId: rawDeviceData.deviceId,
				deviceType: ( () => {
					if ( rawDeviceData.deviceType === AntarConstants.DEVICE_TYPE['CircuitBreaker'] ) {
						return 'Circuit Breaker';
					} else if ( rawDeviceData.deviceType === AntarConstants.DEVICE_TYPE['Fuse'] ) {
						return 'Fuse';
					} else {
						return 'Circuit Breaker';
					}
				} )(),
				deviceLegendName: rawDeviceData['settingsData']?.deviceLegendName,
				legendDataList: legendDataList
			} );
			updatedData.forEach( ( requestd ) => {
				if ( rawDeviceData.deviceId === requestd.deviceId && requestd.celDeviceSettings ) {
					requestd.celDeviceSettings = rawDeviceData.celDeviceSettings;
				}
			} );
			deviceData.push( { ...rawDeviceData, 'settingsData': undefined } );
		} );
		return ( { ...state,
			deviceData,
			deviceSetting,
			networkParameters: responseData['tccDataResponse']['networkParameters'],
			requestData: { ...state.requestData, data: updatedData }

		} );
	} ),
	on( appActions.LoadSelectivityResultDataSuccess, ( state: State, { selectivity }: { selectivity: Selectivity } ) => {
		return ( { ...state,
			selectivity: selectivity,
			requestData: {
				...state.requestData, selectivity
			}
		} );
	} ),
	on( appActions.UpdateLoader, ( state: State, { loadingtype, loader, message }: { loadingtype: string, loader: boolean, message: string } ) => {
		return { ...state,
			loader: {
				mainLoader: loadingtype === AntarConstants.LOADER_TYPE['MAIN_LOADER'] ? { load: loader, message: message } : state.loader.mainLoader,
				informationLoader: loadingtype === AntarConstants.LOADER_TYPE['INFORMATION_LOADER'] ? { load: loader, message: message } : state.loader.informationLoader
			}
		};
	} ),
	on( appActions.SetErrorMessage, ( state: State, { notification }: { notification: Notifications[] } ) => {
		return ( {
			...state,
			notification: notification
		} );
	} ),
	on( appActions.UpdateUserSelection, ( state: State, { chartType }: { chartType: string } ) => {
		return { ...state,
			userSelection: { ...state.userSelection, chartType }
		};
	} ),
	on( appActions.UpdateRcdDeviceRequest, ( state: State, { deviceId, paramName, paramValue }: { deviceId: string, paramName: string, paramValue: string } ) => {
		const updatedRcdDeviceData = state.rcdDeviceData.map( ( rcd: RcdData ) => {
			if ( deviceId === rcd.rcdId ) {
				const updatedRcd = rcd.rcdParameters.map( ( rcdParam: RequestDeviceParameters ) => {
					if ( paramName === rcdParam.paramName.toLowerCase() ) {
						return { ...rcdParam, paramValue: paramValue };
					}
					return rcdParam;
				} );
				return { ...rcd, rcdParameters: updatedRcd };
			}
			return rcd;
		} );
		const updatedUserSettingDevice = state.userSetting.device.map( ( deviceData: UserSetting ) => {
			if ( deviceData.deviceId !== '' && deviceData.deviceId !== null && deviceData.settingName.toLowerCase() === paramName && deviceData.deviceId === deviceId ) {
				return { ...deviceData, deviceId: deviceData.deviceId, settingName: paramName, value: paramValue, deviceName: deviceData.deviceName };
			} else {
				return deviceData;
			}
		} );
		const updatedDeviceSetting = RcdUtils.getRcdSettings( updatedRcdDeviceData );
		return {
			...state,
			rcdDeviceData: updatedRcdDeviceData,
			userSetting: {
				...state.userSetting,
				device: updatedUserSettingDevice
			},
			rcdSettings: updatedDeviceSetting,
			rcdDeviceResponse: processRcdResponse( updatedUserSettingDevice, updatedRcdDeviceData, updatedDeviceSetting )
		};
	} ),
	on( appActions.LoadSelectivityResultDataByTableSuccess, ( state: State, { selectivity }: { selectivity: Response } ) => {
		return ( { ...state,
			selectivityByTable: selectivity,
			requestData: {
				...state.requestData,
				selectivityByTable: selectivity.data
			}
		} );
	} )
);
export function processRcdResponse ( rcdUserSettingsData: UserSetting[], rcdDeviceInfo: RcdData[], rcdDeviceSettings: DeviceSetting[] ):RcdResponseInfo []
{
	let rcdUserSettings: UserSetting[] = [];
	rcdUserSettings = rcdUserSettingsData.filter( ( data: UserSetting ) => {
		if ( AntarConstants.RCD_SETTINGS_NAME.includes( data.settingName.toLowerCase() ) ) {
			return data;
		}
		return false;
	} );
	const rcdDeviceResponse = RcdUtils.getRcdData( rcdDeviceInfo, rcdUserSettings );
	rcdDeviceResponse.forEach( ( rcd: RcdResponseInfo ) => {
		rcdDeviceSettings.forEach( data => {
			if ( rcd.rcdId === data.rcdId )
				rcd.settingsData.push( data );
		} );
	} );
	return rcdDeviceResponse;
}
