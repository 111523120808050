import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/core/services/token.service';
import { environment } from 'src/environments/environment';
import { AntarConstants } from '../antar.constants';

@Injectable( {
	providedIn: 'root'
} )

export class AntaresIntercepter implements HttpInterceptor {

	constructor ( private tokenService: TokenService ) {}

	intercept ( req: HttpRequest<unknown>, next: HttpHandler ): Observable<HttpEvent<unknown>> {
		const auth = environment.launchSystem === AntarConstants.LAUNCHSYSTEM.CEL ?
			req.clone( {
				headers: new HttpHeaders( {
					'Authorization': `Bearer ${this.tokenService.getCelToken()}`
				} )
			} ) :
			req.clone( {
				headers: new HttpHeaders( {
					'Authorization': `Bearer ${this.tokenService.getCelToken()}`,
					'API-Token': `Bearer ${this.tokenService.getAntaresToken()}`
				} )
			} );
		return next.handle( auth );
	}
}
