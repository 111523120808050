import { RcdData, RequestDeviceParameters } from '../common/models/antart-api-request.model';
import { DataPoints, DeviceSetting, LegendData, Point, RangeData, RcdResponseInfo, UserSetting } from '../common/models/antar-api-response.model';
import { AntarConstants } from '../common/antar.constants';

export class RcdUtils {
	static rcdDevice: { sensitivity: number, breakTime: number };
	static dataPoints: DataPoints[];
	static points: number[][][] = [];
	static rcdResponse: RcdResponseInfo[] = [];
	static point: Point[] = [];
	public static getRcdData ( data: RcdData[], rcdUserSettings: UserSetting[] ): RcdResponseInfo[]
	{
		this.rcdResponse = [];
		data.forEach( ( rcd: RcdData ) => {
			if ( rcd.breakerPoisitioning === AntarConstants.BREAKER_POSITIONING['incomer'] )
			{
				this.getRcdValues( rcdUserSettings, rcd );
			}
			if ( rcd.breakerPoisitioning === AntarConstants.BREAKER_POSITIONING['outgoing'] || rcd.breakerPoisitioning === '' )
			{
				this.getRcdValues( rcdUserSettings, rcd );
			}
		} );
		return this.rcdResponse;
	}
	public static getRcdSettings ( rcd: RcdData [] ): DeviceSetting []
	{
		const rcdDeviceSettings: DeviceSetting[] = [];
		let rcdLegendDataList: LegendData[] = [];
		rcd.forEach( ( data: RcdData ) => {
			rcdLegendDataList = [];
			data.rcdParameters.forEach( ( element: RequestDeviceParameters ) => {
				const rcdLegend = {} as LegendData;
				const rcdRangeData = {} as RangeData;
				if ( AntarConstants.POSITION.includes( data.breakerPoisitioning ?? '' ) || data.breakerPoisitioning === '' )
				{
					if ( element.paramName.toLowerCase() === AntarConstants.RCD_SETTINGS['I∆n'] )
					{
						rcdRangeData.values = [ ...data.sensitivityList ].sort( ( a, b ) => a - b );
						rcdRangeData.defaultValue = parseFloat( element.paramValue );
					}
					if ( element.paramName.toLowerCase() === AntarConstants.RCD_SETTINGS['∆t'] )
					{
						rcdRangeData.values = [ ...data.tempoTimeList ].sort( ( a, b ) => a - b );
						rcdRangeData.defaultValue = parseFloat( element.paramValue );
					}
					rcdRangeData.isAdjustable = data.isAdjustable;
					rcdLegend.settingName = element.paramName;
					rcdLegend.rangeData = rcdRangeData;

					rcdLegendDataList.push( rcdLegend );
				}
			} );
			rcdDeviceSettings.push( {
				deviceId: data.deviceId ?? '',
				deviceType: 'RCD',
				rcdId: data.rcdId,
				deviceLegendName: data.deviceId === '' ? `${data.rcdDesignation} ${data.rating} ${data.sensitivity} ` : `${data.rcdDesignation}`,
				legendDataList: rcdLegendDataList
			} );
		} );
		return rcdDeviceSettings;
	}
	private static getResponseDataPoints ( rcdDevice: { sensitivity: number, breakTime: number } ): DataPoints[]
	{
		const data_points: DataPoints[] = [];
		const point = this.getRcdDataPoints( rcdDevice );
		const rcdPoints: DataPoints = {
			curveName: AntarConstants.CEL_CURVE_NAME['Min'],
			deviceType: AntarConstants.DEVICE_TYPE['RCD'],
			points: point
		};
		data_points.push( rcdPoints );

		return data_points;
	}
	public static getRcdDataPoints ( rcd: { sensitivity: number, breakTime: number } ): Point[]
	{
		const leftTop: Point = { x: 0, y: 0 };
		const leftBottom: Point = { x: 0, y: 0 };
		const rightBottom: Point = { x: 0, y: 0 };
		const rcd_Points: Point[] = [];
		leftTop.x = rcd.sensitivity;
		leftTop.y = AntarConstants.RCD_POINTS['LeftTop'];

		leftBottom.y = rcd.breakTime;
		leftBottom.x = rcd.sensitivity;

		rightBottom.x = AntarConstants.RCD_POINTS['RightBottom'];
		rightBottom.y = rcd.breakTime;

		rcd_Points.push( leftTop );
		rcd_Points.push( leftBottom );
		rcd_Points.push( rightBottom );
		return rcd_Points;
	}
	private static getRcdValues ( rcdUserSettings: UserSetting[], rcd: RcdData )
	{
		const rcdValues : { sensitivity: number, breakTime: number } = { sensitivity: 0, breakTime: 0 };
		rcdUserSettings.find( ( item: UserSetting ) => {
			if ( rcd.rcdId === item.deviceId && item.settingName.toLowerCase() === AntarConstants.RCD_SETTINGS['I∆n'] )
			{
				rcdValues.sensitivity = parseFloat( item.value );
			}
		} );
		rcdUserSettings.find( ( item: UserSetting ) => {
			if ( rcd.rcdId === item.deviceId && item.settingName.toLowerCase() === AntarConstants.RCD_SETTINGS['∆t'] )
			{
				rcdValues.breakTime = parseFloat( item.value );
			}
		} );
		this.rcdDevice = {
			...this.rcdDevice,
			sensitivity: rcdValues.sensitivity,
			breakTime: rcdValues.breakTime
		};
		const rcd_point = this.getResponseDataPoints( this.rcdDevice );

		this.rcdResponse.push( {
			dataPoints: rcd_point,
			settingsData: [],
			deviceId: rcd.deviceId,
			rcdId: rcd.rcdId,
			breakerPositioning: rcd.breakerPoisitioning
		} );
	}
}
