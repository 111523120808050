import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromStore from './app.reducer';
import { DataPoints, ResponseDeviceData } from '../common/models/antar-api-response.model';
import { AntarConstants } from '../common/antar.constants';

export const getAppState = createFeatureSelector<fromStore.State>( 'antar' );

export const getUser = createSelector(
	getAppState,
	( state: fromStore.State ) => state.user
);
export const getInputRequestData = createSelector(
	getAppState,
	( state: fromStore.State ) => state.requestData
);
export const getDeviceData = createSelector(
	getAppState,
	( state: fromStore.State ) => state.deviceData
);
export const getSettingsData = createSelector(
	getAppState,
	( state: fromStore.State ) => ( { 'deviceSettings': state.deviceSetting } )
);
export const getUserSettingsData = createSelector(
	getAppState,
	( state: fromStore.State ) => state.userSetting
);
export const getSelectivityResultData = createSelector(
	getAppState,
	( state: fromStore.State ) => ( { 'selectivity': state.selectivity } )
);
export const getLoader = createSelector(
	getAppState,
	( state: fromStore.State ) => ( { 'loader': state.loader } )
);
export const getErrorMessage = createSelector(
	getAppState,
	( state: fromStore.State ) => state.notification
);
export const getRcdResponse = createSelector(
	getAppState,
	( state: fromStore.State ) => state.rcdDeviceResponse
);
export const getRcdSettings = createSelector(
	getAppState,
	( state: fromStore.State ) => state.rcdSettings
);
export const getUserSelectionChart = createSelector(
	getAppState,
	( state: fromStore.State ) => state.userSelection.chartType
);
export const getRcdDeviceData = createSelector(
	getAppState,
	( state: fromStore.State ) => state.rcdDeviceData
);
export const getDevicei2tData = createSelector(
	getDeviceData,
	( deviceDataList: ResponseDeviceData[] ) => {
		let i2tData: DataPoints[] = [];
		deviceDataList.forEach( deviceData => {
			const i2tpoints = deviceData.dataPoints?.filter( ( dataPoint: DataPoints ) => dataPoint.curveType === AntarConstants.CB_CURVE_TYPES['I2t'].toUpperCase() );
			if ( i2tpoints ) {
				i2tData = i2tData.concat( i2tpoints );
			}
		} );
		return i2tData;
	}
);
export const getSelectivityByTable = createSelector(
	getAppState,
	( state: fromStore.State ) => state.selectivityByTable
);
